.card-listing-content {
  margin: 10px;
  max-width: 400px;

  .card-icon-btn:focus{
    outline: none;
  }
  .card-icon-btn:hover{
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.imag-card {
  width: 70px;
}

.modal-delete-card {
  .modal-content {
    width: 75%;
    margin: 0 auto;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
  }
  .delete-card-text{
    text-align: center;
  }
  .delete-card-button{
    margin-top: 7%;
  }
}