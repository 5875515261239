.modal-body-login::-webkit-scrollbar {
  display: none;
}
.modal-body-login {
  max-width: 100%;
  min-height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 20px;

  @media (max-width: 600px) {
    padding: 1rem 0 !important;
    .logo {
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    h3 {
      font-weight: bold;
    }
    img {
      width: 100%;
      max-height: 30vh;
      object-fit: contain;
      margin: 1rem 0;
    }
    p {
      position: absolute;
      bottom: 10px;
      color: #000;
      a {
        color: #fff;
      }
    }
  }
  .login {
    width: 100%;
    min-height: 20vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: #fff;
    .form {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    input {
      width: 90%;
    }
    .buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 0;
      .or {
        width: 40%;
        height: 1px;
        margin: 20px 0;
        display: block;
        border-top: 1px solid #ccc;
        border-bottom: none;
        border-left: none;
        border-right: none;
        text-align: center;
        span {
          background-color: white;
          position: relative;
          top: -0.8em;
          padding: 0 10px;
        }
      }
      button {
        width: 50%;
        font-size: 0.8rem;
        padding: 10px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .terms {
    text-align: center;
    margin-top: 20px;
    flex-basis: 0;
    width: 70%;
    font-size: 12px;
  }
}
