.sidebarCart{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10px;
  .cart-title{
    text-align: center;
    padding: 20px 0;
    color: #fff;
  }
  .close-button{
    position: absolute;
    top: 0;
    right: 0px;
    color: #fff;
    text-transform: capitalize;
  }
  p{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    text-align: center;
    font-size: .8rem;
    font-weight: 500;
    span{
      font-size: .8rem;
      font-weight: bold;
    }
  }
  .total-cart{
    font-weight: bold;
    font-size: 1.1rem;
    margin-top: 1rem;
    span{
      font-size: 1.1rem;
    }
  }
  .delivery-description{
    padding: 0 10px;
  }
  .cart-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: .8rem;
    button{
      width: 95%;
    }
  }
  .tax{
    display: flex;
    justify-content: space-between;
    p{
      margin-right: 10px;
    }
  }
}

.restricted-products-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  text-align: center;
  
  h5 {
    font-weight: bold;
  }
}