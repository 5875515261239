.products-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.products-modal-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 24px;

  .product {
    width: calc(33.33% - 16px);
    margin-bottom: 24px;
  }
}

@media (max-width: 600px) {
  .modal-header {
    padding: 0.5rem 0;
  }
  .modal-body {
    padding: 0;
  }

}