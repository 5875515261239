.order-delivery-infos{
  h5{
    margin-bottom: 0;
    font-weight: bold;
  }
  p{
    margin-bottom: 5px;
    font-weight: normal;
    font-size: 14px;
    &.cep{
      font-weight: bold;
    }
  }
  .delivery-infos{
    span{
      font-weight: lighter;
      font-size: 12px;
    }
    p{
      margin-bottom: 5px;
      font-weight: 400;
      font-size: 16px;
    }
  }
  .change-button{
    text-decoration: underline;
    text-transform: capitalize;
    font-size: 14px;
  }
}

.order-delivery-modal{
  .modal-body{
    flex-direction: column;
    padding: 1rem 0;
  }
  .title{
    width: 100%;
    h5{
      font-weight: bold;
      margin: 0;
    }
  }
}

.order-delivery-inputs-form{
  display: flex;
  justify-content: space-between;
  .input-item{
    margin-top: 10px;
  }
}

@media screen and (max-width: 650px) {
  .order-delivery-modal{
    width: 100vw;
    margin-left: 0;
    justify-content: center;
    .modal-content{
      width: 95vw;
      .modal-body{
        max-width: 95vw;
      }
    }
  }
}
