.footer-main{
  .footer-stock-message {
    min-height: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    margin-top: 1rem;
  }
}


.storage-main-footer {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 0px 30px 0px 20px;
  height: 6em;

  @media screen and (max-width: 800px) {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    gap: 0.5rem;
  }

  .text-info-footer {
    display: flex; 
    flex-direction: column;
    align-items: flex-start; 
    justify-content: space-evenly;
    @media screen and (max-width: 800px) {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }

  .logo-mercadapp-footer {
    img {
      height: 2.5rem;
      position: relative;
    }
  }
}
