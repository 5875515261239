.nav-link-info-store {
  cursor: pointer;
  width: 100%;
  text-align: center;
  font-weight: bold;

  &.active {
    border-color: transparent !important;
    border-bottom: solid 3px var(--bottom-dynamic-color) !important;
    color:var(--bottom-dynamic-color) !important;
  }

  &:hover {
    border: none;
    font-weight: 600;
  }
}

.info-store-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.containerPaymentMethodImage {
  width: 70px;
  display: flex;
  justify-content: center;

  .paymentMethodImage {
    height: 35px;
    border-radius: 3px;
    margin-right: 5px;
    object-fit: contain;
  }
}
.container-payment-types{
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  .info-payment-types{
    display: flex;
    border-radius: 5px;
  }

  .payment-types-title{
    font-weight: bold;
    color: #fff;
    margin-left: 10px;
  }

  .payment-type-method{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px,
    }
}

