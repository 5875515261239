.cashback-coupon-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    .close-cashback-modal-btn {
        position: absolute;
        top: 12px;
        right: 12px;
    }

    .cashback-modal-title {
        font-weight: bold;
        font-size: 1.5rem;
    }

    p {
        font-weight: 400;
        font-size: 1rem;
        text-align: center;
    }

    .cashback-instruction {
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;
        width: 45%;
        margin: 1rem;
        border-radius: 8px;
        gap: 1rem;

        .cashback-coupon-code {
            font-weight: bold;
            font-size: 1.5rem;
            border-radius: 8px;
            text-align: center;
            padding: 0.25rem;
        }

        ol {
            margin-left: 1rem;
        }

        li {
            margin-bottom: 0.5rem;
        }
    }

    .coupons-navigate-btn {
        text-transform: uppercase;
    }
}

@media screen and (max-width: 768px) {
    .cashback-coupon-modal {
        .cashback-instruction {
            width: 100%;
        }
    }
}