.pix-info {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    gap: 1.5rem;

    .pix-code {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        max-width: 350px;
        margin: 0 auto;

        img {
            background-color: #666;
            width: 50px;
        }

        .pix-code-box {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            width: 100%;

            .pix-code-tip {
                font-size: 0.75rem;
                word-break: break-all;
            }

            .pix-code-copy {
                display: flex;
                align-items: center;
                border-radius: 5px;
                background-color: rgba(204, 204, 204, 0.3);
                justify-content: space-between;

                .pix-code-text {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: .8rem;
                    font-weight: 900;
                    border-radius: 5px;
                    margin: 0.25rem 0.5rem;
                }

                svg {
                    width: 22px;
                    height: 22px;
                    margin-right: .5rem;

                    &:hover {
                        cursor: copy;
                    }
                }
            }
        }
    }

    .pix-timer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        font-size: 0.75rem;

        .timer {
            width: 45px;
            height: 45px;
        }

        .timer-info {
            display: flex;
            flex-direction: column;
        }
    }

    .pix-help-box {
        padding: 0.5rem 0.25rem;

        p {
            margin-bottom: 1rem;
            text-align: center;
            font-weight: bold;
        }

        ul {
            margin-left: 1rem;
            list-style: none;
            font-size: smaller;

            li {
                margin-bottom: 0.5rem;

                b {
                    margin-right: 0.5rem;
                }

                span {
                    color: gray;
                }
            }
        }
    }

    .pix-linx-info {
        font-size: smaller;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .pix-info {
        .pix-code {
            flex-direction: column;
            max-width: 100%;
        }
    }
}