.change-time-title-day{
  color: #fff;
  font-size: bold;
}
.box-button-change-time{
  .available-times-request{
    margin: 10px;
  }
}
.div-button-change-time{
  display: flex;
  justify-content: center;
  .button-change-time{
    width: 35%;
  }
}
