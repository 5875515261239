.chat-order-messages {
  height: 90%;
}
.header-order-chat {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  color: #fff !important;
  padding: 10px;
  .modal-title {
    width: 90%;
    color: #fff;
    text-align: left;
    font-weight: 500;
    margin-bottom: 0;
  }
  button {
    width: 13%;
    text-align: center;
    background-color: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
  }
}
.input-order-chat {
  width: 350px;
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 10px;
  position: fixed;
  input {
    width: 90%;
    padding: 5px 5px;
    border-color: #ccc;
    border-style: solid;
    border-radius: 5px;
    box-shadow: none;
    text-align: left;
    font-weight: 500;
    margin-bottom: 0;
  }
  button {
    width: 10%;
    text-align: center;
    background-color: transparent;
    color: #ccc;
    border: none;
    cursor: pointer;
  }
}

.modal-order-chat {
  .modal-content {
    height: 50vh;
    max-height: 50vh;
    .modal-body {
      border-bottom-left-radius: 0.4375rem;
      border-bottom-right-radius: 0.4375rem;
    }
  }
}
