.friend-campaign {
  display: flex;
  flex-direction: column;
  align-items: center;
  .sale-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
    .sale-header {
      font-size: 1.2rem;
      font-weight: 600;
      margin: 0 1rem;
    }
    .sale {
      font-size: 1.5rem;
      font-weight: bold;
      margin: 0;
    }
  }
  .share-code{
    width: 100%;
    margin-bottom: 5px;
  }
  .friend-code {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    width: 100%;
    justify-content: center;
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    padding: 1rem 0;
    p {
      font-size: 1.5rem;
      margin: 0;
      margin-right: 1.5rem;
    }
    span{
      cursor: pointer;
      &:hover{
        transform: translateY(-1px);
        font-weight: bold;
      }
    }
  }
}
