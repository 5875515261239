.order-coupon {
  .title-coupon {
    h5 {
      font-weight: bold;
      margin: 0;
    }
  }

  .coupon-details {
    .coupon-disponible {
      margin-bottom: 5px;
      font-size: 20px;
      font-weight: bold;
    }

    label {
      font-size: 12px;
      margin-bottom: 20px;
    }

    button {
      width: 45%;
      text-transform: capitalize;
    }
  }

  .discount-applied {
    .cupom-title {
      font-size: 14px;
      font-weight: normal;
      margin: 0;
      margin-top: 5px;
    }

    .cupom-code {
      margin: 10px 0;
      border: solid 1px;
      border-radius: 5px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        display: flex;
        align-items: center;
        margin: 5px 0;
        font-weight: normal;

        svg {
          margin-left: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .order-coupon {
    margin-top: 1rem !important;
  }
}