.modalPixStatus {
  max-width: 320px;
  .modal-top {
    height: 12px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  .container {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    .title {
      text-align: center;
      margin-bottom: 30px;
      flex-direction: row;
      font-size: 22px;
      font-weight: 900;
      font-family: lato;
      color: #1b1b1b;
    }
    button {
      border-radius: 5px;
      margin: 15px auto 0px;
      width: 80%;
      border-style: none;
      padding: 10px 15px;
      font-size: 14px;
      font-family: lato;
      font-weight: 900;
      color: #fff;
      cursor: pointer;
    }
  }
}
