.acceptance-box-container {
    border: 1px solid #d9d9d9;
    margin: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 0.5rem;

    .acceptance-box-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h6 {
        font-weight: bold;
        font-size: 1rem;
      }

      button {
        width: 10px;
        height: 10px;
        margin-left: 5px;
        outline: none;
      }
  
      button:hover {
        cursor: pointer;
      }

    }

  
    .acceptance-box-mid {
      p {
        font-size: smaller;
        font-weight: bolder;
      }
    }
  
    .acceptance-box-bottom {  
      div {
        display: flex;
  
        p {
          font-size: smaller;
          font-weight: normal;
          margin-bottom: 0;
        }
  
      }
    }
  }
  