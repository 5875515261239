.checkbox-items{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 30px auto;
    width: 90%;
    .checkbox-text{
        font-size: 14px;
        font-weight: bold;
        border-bottom: solid 1px #ccc;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.checkbox-text-info{
    text-align: center;
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid;
    margin: 20px auto;
}