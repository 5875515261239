.bannerStore {
  @media (max-width: 576px) {
    max-height: 140px;
    border-radius: 0 !important;
  }
}

.has-publicity {
  position: absolute;
  bottom: -10px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.616);
  padding: 5px 15px;
  color: #000;
  font-weight: bold;
  img {
    height: 24px;
    margin-right: 5px;
  }
}

.fixed-banners {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 599px) {
    display: none;
  }

  img {
    @media (max-width: 970px) {
      display: none;
    }
    max-width: calc(50% - 15px);
    border-radius: 5px;
    max-height: 45%;
  }
}

.carousel-banner-one {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -10px auto 50px !important;

  @media (max-width: 959px) {
    padding-top: 0;
  }

  @media (max-width: 599px) {
    margin: auto -4%  !important;
  }
}

.carousel-banner-two {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 50px !important;

  @media (max-width: 959px) {
    padding: 0 1rem;
    margin-bottom: 0 !important;
  }

  @media (max-width: 599px) {
    padding: 5px !important;
    margin-bottom: 30px !important;
  }
}

.carousel-swipe {
  width: 100%;
  margin: 0 auto -50px auto;
  cursor: pointer;
}

.variable-banner {
  width: 100%;

  @media (max-width: 599px) {
    padding: 0 !important;
  }
}
