
.headroom {
    will-change: transform;
    background-color: inherit;
    @include transition($transition-base);
}
.headroom--pinned {
	@extend .position-fixed;
    transform: translateY(0%);
}
.headroom--unpinned {
	@extend .position-fixed;
    transform: translateY(-100%);
}

.headroom--not-top {
	padding-top: .5rem;
	padding-bottom: .5rem;
    background-color: theme-color("warning") !important;
    box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}