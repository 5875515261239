.modal-client-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  button {
    width: 100px;
    justify-self: flex-end;
    margin-left: auto;
  }

  .errors-infos {
    font-size: 12px;
    color: #808080;
    margin-bottom: 10px;
  }
}