.dialog-simple {
  .dialog-content-simple {
    width: 400px;
    height: 280px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}