.modal-info-notification{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -20px;
    .logo-image-notification{
        img{
            max-width: 100px;
        }
    }
    .title-notification{
        padding: 10px 0;
        font-weight: bold;
    }
    .title-notification-link{
        margin: 30px auto 10px;
        font-weight: bold;
    }
    .title-notification-button:hover{
        border: none;
    }
    .button-confirme{
        text-transform: capitalize;
        width: 220px;
        margin: 10px 0;
    }
}
