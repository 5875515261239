.list-button {
  width: 100%;
  padding-right: 15px;
  background: transparent;
  box-shadow: none;
  border-color: #ccc;
  margin-bottom: 15px;
  padding: 10px 15px;
  overflow: auto;
  text-align: start;
  text-transform: none;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #ccc;
  button {
    width: 95%;
    overflow: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-radius: 0;
    text-transform: capitalize;
    padding: 0;
    font-weight: 500;
    .list-description{
      display: flex;
      align-items: center;
      font-size: 1rem;
    }
    i{
      font-size: .9rem;
    }
    &:hover,
    &:active,
    &:focus {
      box-shadow: none !important;
      background-color: transparent !important;
    }
  }
  .last-update{
    color: #ccc;
    font-weight: 600;
    font-size: .75rem;
    text-transform: initial;
    padding-left: 10px;
    // padding-top: 4px;
    margin-left: 10px;
    border-left: solid 1px #ccc;
  }
  .delete{
    text-align: end;
    width: 10%;
    color: #d70101;
    cursor: pointer;
    &:hover{
      transform: translateY(-1px);
    }
    i {
    }
  }

  .text-details {
    font-size: 0.75rem;
    margin-bottom: 5px;
  }

  .order-number {
    font-size: 1rem;
    font-weight: bold;
  }
}

.btn:not(:last-child) {
  margin-right: 0 !important;
}
