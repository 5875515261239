.warning-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.warning-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 15px 25px;
    border-radius: 5px;
    width: 320px;
    min-height: 300px;
    justify-content: space-evenly;
    text-align: center;
    animation: slide-in 0.3s ease-in-out forwards;

    @keyframes slide-in {
        from {
            transform: translateY(-100%);
        }

        to {
            transform: translateY(0);
        }
    }

    h5 {
        font-weight: bolder;
    }

    button {
        color: #fff;
        width: 80%;

        &:focus {
            outline: none;
        }
    }
}