.promotion-text {
  font-weight: 700 !important;
  width: 100%;
  text-align: end !important;
  font-style: italic;
}
.store-card-product {
  max-height: 300px;
  // max-width: 200px !important;
  margin-bottom: 20px !important;
  padding-bottom: 10px;
  cursor: pointer;
  img {
    height: 40%;
  }
  button {
    width: 65%;
    // margin-top: 10px;
    border: solid 1px #d67c1b;
    border-radius: 15px;
    .text{
      margin-left: .5rem;
      text-transform: capitalize;
      font-size: 1rem;
    }
  }
  .card-product {
    width: 100%;
    height: 100%;
    min-width: 10rem;
    min-height: 10rem;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 2px 2px 6px #e8e8e8;
    padding: 10px 0;
    .offer {
      position: absolute;
      top: 10px;
      left: 10px;
      height: 20%;
      color: #fff;
      padding: 5px 0px;
      border-radius: 10px;
      font-weight: bold;
    }
    .free-delivery {
      position: absolute;
      left: 5px;
      width: 25%;
      text-align: center;
      font-weight: 600;
      color: #d67c1b;
      line-height: 1rem;
    }
    .mix-toggle {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      max-width: 100%;

      button {
        opacity: .25;
        width: 10px;
        height: 10px;
      }

      button:hover {
        cursor: pointer;
      }

      button:focus {
        outline: none;
      }

      .selected-btn {
        width: 12px;
        height: 12px;
        opacity: 1;
      }
    }
    .size {
      position: absolute;
      top: 40%;
      right: 12px;
      font-weight: 600;
      color: #979191;
    }
    .promotion-flag {
      position: absolute;
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      right: 10px;
      font-size: 12px;
      // line-height: 1.4rem;
      span {
        color: #000;
        font-weight: 600;
        text-align: center;
      }
      .number-or-qtd {
        width: max-content;
        background-color: #d67c1b;
        padding: 5px 15px;
        color: #fff;
        border-radius: 12px;
      }
    }
  }
  .product-description {
    width: 70%;
    text-align: center;
    position: relative;
    // margin-top: 15px;
    p {
      color: #000;
      font-weight: normal;
      margin-bottom: 2px;
      height: 2.4rem;
      text-transform: capitalize;
      font-size: 0.8rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }
    .current-price-product {
      font-weight: bolder;
      font-size: 1.4rem;
      height: min-content;
      margin-bottom: -5px;
      span {
        font-size: 1.1rem;
      }
      @media screen and (max-width: 400px) {
        font-size: 1rem;
      }
    }
    .offer-price {
      font-weight: inherit;
      height: auto;
      color: rgb(70, 70, 70);
      font-size: 0.8rem;
      margin-top: -5px;
      margin-bottom: -12px;
      span {
        font-size: 0.5rem;
      }
    }
    .kilo-price {
      font-weight: inherit;
      height: auto;
      color: white;
      border-radius: 50px;
      font-style: italic;
      font-size: 0.8rem;
      margin-top: 2px;
      margin-bottom: -2px;
    }
  }
  .product-available-stock {
    border-radius: 8px;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: 0 0.5rem;
  }
  .amount-area {
    width: 90%;
    height: 12%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    border: solid 1px #e5e5e5;
    border-radius: 10px;
    overflow: hidden;
    .amount-button {
      width: 25%;
      min-width: 25%;
      font-size: 18px;
      font-weight: bold;
      border: none;
      background-color: #e5e5e5;
      border-radius: 0;
      // border-top-left-radius: 10px;
      // border-bottom-left-radius: 10px;
    }
  }
}
