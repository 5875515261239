.pix-status {
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 0.5rem 1rem !important;
    min-height: 50px;

    svg {
        margin-right: 1rem;
    }

    div {
        display: flex;
        flex-direction: column;
        text-align: left;
        font-size: smaller;

        strong {
            margin-left: 12px;
        }
    
        span {
            margin-left: 12px;
        }
    }
}

.pix-canceled {
    background: rgba(89, 89, 89, 0.05);
    border: 1px solid #D9D9D9;
    font-weight: bold
}

.pix-received {
    background: rgba(66, 142, 35, 0.2);
    font-weight: bold;
    color: #428E23; 
}