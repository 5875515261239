.list-header {
  .input-title {
    width: 70%;
    color: #000;
    font-size: 1.5rem;
    text-align: center;
    border-color: #ccc;
    border-radius: 10px;
  }
  .buttons-input {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
    .obs-input {
      font-size: 0.75rem;
      color: #ccc;
      text-align: end;
      cursor: pointer;
      &:hover {
        transform: translateY(-1px);
      }
    }
  }
  .modal-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    .edit-title {
      cursor: pointer;
      font-size: 14px;
      color: #d78022;
      font-weight: bold;
      width: 100%;
      text-align: start;
      i{
        margin-right: 5px;
      }
      &:hover {
        transform: translateY(-1px);
      }
    }
  }
  .close {
    position: absolute;
    right: 20px;
  }
}
.share-button-list {
  background-color: transparent;
  border: none;
  box-shadow: none;
  text-transform: capitalize;
  font-weight: bold;
  // margin-top: 20px;
  i {
    margin-right: 10px;
  }
  &:hover,
  &:active,
  &:focus {
    background-color: #eaeaea !important;
  }
}

.last-update-list {
  font-size: 0.75rem;
  font-weight: lighter;
  position: absolute;
  top: 10px;
  right: 10px;
}

.list-items {
  .item-description {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 12px;
    margin-bottom: 5px;
    border-bottom: solid 1px #ccc;
    img {
      width: 5rem;
      height: 4rem;
      object-fit: contain;
      border: solid 1px #f2f2f2;
      border-radius: 10px;
      margin-right: 10px;
    }
    p {
      max-width: 80%;
      margin: 0;
      font-size: 0.8rem;
      text-align: start;
      max-height: 2.8rem;
      overflow: hidden;
      span {
        font-weight: inherit;
        margin-right: 5px;
      }
    }
    span {
      font-weight: bold;
    }
    .item-details {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .item-user {
        width: 50%;
        font-size: 0.8rem;
        font-weight: lighter;
        text-align: end;
      }
    }
  }
}

.cart-button-list {
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    background-color: transparent;
    border: none;
    text-transform: capitalize;
    font-weight: bold;
    margin-top: 20px;
    &:hover,
    &:active,
    &:focus {
      background-color: #eaeaea !important;
    }
  }
}
