.title-flipbooks{
  text-align: center;
  font-weight: bold;
  margin-bottom: 2rem;
}
.flip-card {
  // max-height: 35VH;
  cursor: pointer;
  p{
    font-weight: bold;
    text-align: center;
  }
  img {
    height: 35ch;
    @media (max-width: 700px) {
      height: 20ch;
      object-fit: contain;
    }
  }
  &:hover{
    transform: translateY(-2px);
  }
}
