.modal-birthday{
  display: flex;
  flex-direction: column;
  height: 40vh;
  padding: 25px !important;
  p{
    font-size: 1.2rem;
    font-weight: normal;
    text-align: center;
  } 
  label{
    text-align: center;
    margin-bottom: 1rem;
  }
  .form-birthdate{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 5%;
    .MuiFormControl-root{
      width: 50%;
    }
    button{
      margin-left: auto;
      margin-top: auto;
    }
  }
}