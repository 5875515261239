.share-buttons{
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
}
.share-link{
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  p{
    width: 60%;
    overflow: scroll;
    margin-bottom: 0;
    padding: 0 15px;
    border: solid 1px #ccc;
    border-radius: 5px;
  }
  .share-text{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .copy-button{
    cursor: pointer;
    width: 25%;
    font-size: .75rem;
    font-weight: bold;
    padding: 10px;
    &:hover{
      transform: translateY(-1px);
    }
  }
  .copied-span{
    width: 88%;
    text-align: start;
    color: green;
    font-size: 12px;
  }
}