.input-container {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0 10px;
  
    span {
      color: #000;
      margin: 0 5px;
    }
  
    input {
      border: none;
      outline: none;
      width: 85%;
    }
  
    button {
      border: none;
      width: 20px;
      height: 20px;
      outline: none;
    }
  }
  