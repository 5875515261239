.change-brand-modal-title {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 1rem;

    h6 {
        font-weight: bold;
    }

    button {
        position: absolute;
        right: -15px;
        top: -15px;
    }
}

.change-brand-modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    button {
        margin-left: 1rem;
        text-transform: capitalize;
        font-weight: bold;

        &::hover{
            cursor: pointer;
        }
    }
}

form label {
    margin-bottom: 0.25rem;
}