.paper-container-person-data {
  .span-info-user-data{
    font-size: 12px;
    font-weight: 700;
  }
  .select-user-gender{
    width: 100%;
    border-radius: 3%;
  }
  .person-data-my-account {
    width: 100%;
    display: flex;
    justify-content: space-between;

    span {
      font-weight: 800;
    }

    .button-delete-account {
      color: red;
      text-transform: none;
    }
  }
}