.add-remove-component{
    min-height: 20px;
    min-width: 100px;
    .amount{
        font-weight: 500;
    }
    .input-wrapper {
        text-align: center;
        display: flex;

        input {
            height: auto;
            padding: 0;
            text-align: center;
            border: 0;
            font-weight: bold;
        }
    }
    .icon-button{
        display: flex;
        align-items: center;
        min-width: 0;
        padding: 0 2px;
        border-radius: 10px;
        min-height: 20px;
        span{
            display: flex;
            font-size: 16px;
        }
    }
}