.div-modal {
  display: flex;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;

  .card-produtos {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .revenue-warning-box {
      display: flex;
      gap: 1rem;
      padding: 1rem;
      background-color: #fdede9;;
      border-radius: 8px;
      width: 100%;
      margin: 1rem;

      div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        h6 {
          font-weight: bold;
          font-size: .9rem;
          color: #000;
        }

      }

      svg {
        color: #b5210c;
        margin-top: 0.25rem
      }
    }
  }

  .card-info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 30px;
  }

  .card-info-text {
    font-weight: bold;
  }

  .card-products-relate {
    display: flex;
    align-items: center;

    .products-relate-carousel {
      display: flex;
      overflow-x: scroll;
      scroll-behavior: smooth;
    }

    .products-relate-carousel::-webkit-scrollbar {
      display: none;
    }

    .products-relate-btn {
      margin: 0 -20px 5% -20px;
      z-index: 5;
      color: #FFF;
    }

    @media screen and (min-width: 1200px) {
      .products-relate-carousel {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
      }

      .products-relate-btn {
        display: none;
      }
    }
  }
}

.div-image {
  display: flex;
  align-items: center;
  position: relative;

  .product-publicity {
    top: 0;
    left: 0;
    bottom: inherit;
    right: inherit;
    border-radius: 5px;
    font-size: 12px;
  }

  .offer {
    position: absolute;
    top: 20px;
    left: 30px;
    height: 25%;
    color: #fff;
    padding: 5px 0px;
    border-radius: 10px;
    font-weight: bold;
  }

  .free-delivery {
    position: absolute;
    left: 5px;
    width: 80px;
    text-align: center;
    font-weight: 600;
    color: #d67c1b;
    line-height: 1rem;
  }

  .promotion-flag {
    position: absolute;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 10px;
    font-size: 14px;

    // line-height: 1.4rem;
    span {
      color: #000;
      font-weight: 600;
      text-align: center;
      margin-bottom: 5px;
    }

    .number-or-qtd {
      width: max-content;
      background-color: #d67c1b;
      padding: 5px 15px;
      color: #fff;
      border-radius: 12px;
    }
  }
}

.image {
  object-fit: contain;
  padding: 20px;
  height: 40vh;
  margin-left: auto;
  margin-right: auto;
}

.mini-image {
  height: 80px;
  width: 80px;
  position: absolute;
  left: 0;
  border: solid 2px #d67c1b;
  border-radius: 10px;
  object-fit: contain;
}

.amount-kilo {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 80px;
  height: 30px;
  text-align: center;
  border-radius: 10px;
}

.col-div-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
}

.div-info {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
}

.div-info-title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 0.5rem;
}

.div-info-subtitle {
  font-size: 16px;
  font-weight: bold;
  margin-top: 0.5rem;
}

.div-info-body {
  font-size: 14px;
  font-weight: normal;
  color: #565656;
  margin-top: 10px;
}

.div-info-bar-code {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 1rem;
  color: #565656;
}

.div-info-input {
  justify-content: center;
  margin: 0.5rem 0;
}

.div-info-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 5.5rem;
  justify-content: space-between;
  padding-left: 30px;



  .discount-price {
    font-size: 12px;
    border-radius: 4px;
    padding: 8px 10px;
    margin-bottom: 10px;
    box-shadow: 1px 2px rgb(0 0 0 / 20%);
  }


  .info-price-original {
    font-weight: 400;
    font-size: 16px;
    color: #808080;
    margin-top: revert;
    margin-bottom: unset;
  }
  
  .price-product-modal p {
    margin: 0;
    font-size: 16px;
  }


  .change-button {
    text-decoration: underline;
    text-transform: capitalize;
    font-size: 15px;
    padding-left: 0;
    margin: 15px 0 15px;
    &:focus{
      outline: none
    }
  }

  .change-button span {
    font-size: 16px;
  }

  .button-cart {
    padding: 10px;
    font-size: 16px;
  }

  .btn-add-cart {
    .add-remove-component {
      min-height: 25px;
      min-width: 150px;

      .amount {
        font-size: 18px !important;
        margin: 0 15px;
      }

      .icon-button {
        border-radius: 30px;
        min-height: 30px;
        min-width: 30px;
      }

      button {
        span {
          font-size: 22px !important;
        }
      }
    }
  }
}

.div-info-price-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 5vh;
  width: 25vh;

  .input-quantity {
    height: auto;
    padding: 0;
    text-align: center;
  }
}

.div-footer {
  border-top: solid 2px #f2f2f2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3rem;
  padding-top: 3rem;
  width: 100%;
}

.div-footer-2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
}

.div-total-price {
  display: flex;
  flex-direction: column;
}

.preferences-modal {
  .MuiDialog-paperWidthMd{
    max-width: 445px;
    border-radius: 2%;
  }
}

.preferences-modal-info {
  width: 100%;
  margin: 0;

  h5 {
    font-weight: bold;
    margin: 0;
  }

  .preferences-modal-info-text {
    width: 100%;

    .MuiOutlinedInput-multiline {
      padding: 6px;
    }

    .MuiOutlinedInput-root {
      border-radius: 10px;
    }
  }

  .preferences-modal-info-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .info-button {
      padding: 10px 38px;
      font-weight: bold;
      border-radius: 5px;
    }
  }
}