.menu-buttons {
  display: flex;
  align-items: center;
  font-size: 14px !important;
  border-radius: 5px !important;

  &:focus {
    outline: none;
  }

  .menu-span {
    padding-left: 5px;
    font-weight: bold;
  }
}

.menu-categories {
  .icon-button {
    width: 15px;
    color: #fff;
    border-radius: 5px;
  }
  .icon-button-categories{
    margin-right: 10px;
  }

  @media screen and (max-width: 599px) {
    display: none;
  }
}

.menu-scroller {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

.menu-scroller::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}