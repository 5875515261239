.chat-message {
  width: max-content;
  max-width: 90%;
  background-color: rgb(227, 227, 227);
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px 10px;
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
    width: 95%;
    text-align: start;
    color: #565656;
  }
  span {
    font-size: 0.8rem;
    font-weight: bold;
  }
  .message-text {
    display: flex;
    align-items: right;
    flex-direction: column;
    word-wrap: break-word;
  }
  .message-time {
    width: 100%;
    text-align: end;
    font-weight: 300;
    font-size: 10px;
    color: #565656;
  }
}
