.order-infos {

    h5 {
        font-family: Lato;
        font-weight: bold;
        margin: 1rem 0;
    }

    .order-infos-box {
        display: flex;
        flex-direction: column;

        div {
            display: flex;

            b {
                margin-right: 5px;
            }
        }
    }

}

@media (max-width: 768px) {
    .order-infos {
        .order-infos-box {
            gap: 0.5rem;
            div {
                flex-direction: column;
            }
        }
    }
}