.container-related-items {
    .carousel-arrow-button {
        display: flex;
        align-items: center;
        .carousel {
            display: flex;
            overflow-x: auto;
            scroll-behavior: smooth;
        }
        .carousel::-webkit-scrollbar {
            display: none;
        }
        .button-arrow {
            color: #fff;
            margin: 0 -20px 5% -20px;
            z-index: 1;
        }

    }
}