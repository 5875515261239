.market-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  margin-bottom: 1rem;
  gap: 0.5rem;
  
  @media screen and (max-width: 992px) {
    margin-top: 2rem !important;
  }
  
  @media screen and (max-width: 599px) {
    margin-top: 10px;
  }

  img {
    width: 150px;

    &:hover {
      cursor: pointer
    }
  }

  p {
    font-size: 1rem;
  }

  .side-link-info {
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
  }
  .side-delivery-method-text,
  .link-text-info{
    font-size: 12px;
    text-align: center;
  }
}
