.order-client-infos{
  h5{
    margin-bottom: 0;
    font-weight: bold;
  }
  .client-infos{
    span{
      font-weight: lighter;
      font-size: 12px;
    }
    p{
      margin-bottom: 5px;
      font-weight: 400;
      font-size: 16px;
    }
  }
  .change-button{
    text-decoration: underline;
    text-transform: inherit;
    font-size: 14px;
  }
}

.order-client-modal{
  max-width: initial;
  
  .modal-content{
    width: 60vw;
  }
  .modal-body{
    flex-direction: column;
    padding: 1rem 0;
    .client-firebase-content{
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      .firebaseui_container{
        width: 100%;
      }
    }
    .modal-client-footer{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      .errors-infos{
        font-size: 12px;
        color: #808080;
        margin-bottom: 10px;
      }
    }
  }
  .title{
    width: 100%;
    h5{
      font-weight: bold;
      margin: 0;
    }
  }
  .change-button{
    text-decoration: underline;
    text-transform: inherit;
    font-size: 14px;
  }
}

@media screen and (max-width: 650px) {
  .order-client-modal{
    width: 100vw;
    margin-left: 0;
    justify-content: center;
    .modal-content{
      width: 95vw;
      .modal-body{
        max-width: 95vw;
      }
    }
  }
}
