.order-status {
    border-radius: 5px;
    width: min-content;
    font-weight: bold;
    font-size: 0.8rem;
    text-align: center;
    display: flex;

    span {
        margin: 0.25rem 0.5rem;
    }
}

.received {
    background: rgba(66, 142, 35, 0.2);
    color: #428E23;
}

.waiting {
    background: rgba(251, 188, 4, 0.2);
    color: #FBBC04;
}

.canceled {
    background: rgba(216, 35, 42, 0.2);
    color: #D8232A
}