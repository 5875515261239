.coupon-card {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .coupon-top-box {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    span {
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  .coupon-infos {
    display: flex;
    flex-direction: column;

    b {
      text-transform: uppercase;
    }

    span {
      font-weight: 400;
    }
  }

  .copy-code-btn {
    border-width: 2px;
    font-weight: bold;

    span {
      margin-left: 0.5rem;
    }

    &:hover {
      border-width: 2px;
    }

    &:focus {
      outline: none;
    }
  }

  .coupon-min-purchase {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    margin: 0 1rem
  }

  .coupon-details {
    ul {
      margin-top: 0.5rem;
      margin-left: 1.5rem;
      font-weight: 400;

      li {
        margin-bottom: 0.25rem;
      }
    }
  }

  .show-details-btn {
    text-transform: capitalize;
    width: auto;
    align-self: flex-start;
    font-weight: bold;

    &:focus {
      outline: none;
    }
  }
}