.order-products-list {
    .order-products-list-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h6 {
            font-weight: bold;
            margin: 1rem 0;
        }

        button {
            text-transform: capitalize;
            max-height: 40px;
        }

    }

    .order-product {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: #dddddd 1px solid;
        padding: 1rem 0;

        .order-product-info {
            display: flex;
            width: 100%;
            margin: 0.25rem 0;
            gap: 0.5rem;

            b {
                min-width: 55px;
            }

            img {
                width: 40px;
                height: 40px;
            }

            span {
                text-transform: capitalize;
            }
        }
    }
}