.top-floating-button{
    bottom: 40px;
    width: 200px;
    float: right;
.button-topo {
    background-color: white;
    border: none;
    border-radius: 20px;
    height: 45px;
    width: 130px;
    
    align-items: center;
    overflow:hidden;
    max-width: 32px;
    -webkit-transition: max-width 0.5s;
    transition: max-width 0.5s;
    .icon{
      margin-left: -12px;
    }
    .text{
      font-size: 10px;
      text-transform: none;
    }
  }

  .button-topo:hover {
    max-width: 300px;
  }
}

.button-back-top{
  position: fixed !important;
  bottom: 45px;
  right: 3vw;
  width: 5px;
  height: 60px;
  .button-topo{
    background-color: #fff;
    border: none;
    border-radius: 20px;
    height: 45px;
    width: 130px;
    display: flex;
    align-items: center;
    overflow:hidden;
    max-width: 32px;
    -webkit-transition: max-width 0.5s;
    transition: max-width 0.5s;
    .icon{
      margin-left: -12px;
    }
    .text{
      font-size: 10px;
      text-transform: none;
    }
  }
  .button-topo:hover {
    max-width: 300px;
  }
}
.button-back-top-secundary{
  display: flex;
  justify-content: flex-end;
  right: 25px;
  width: 130px;
  @media (max-width: 576px) {
    .button-topo:hover {
      max-width: 10px;
    }
  }
}
.btn-primary:not(:disabled):not(.disabled):active{
  background-color: #fff;
}