.products-sorter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;

    .form-control {
        background-color: transparent;
        border: none;
        font-weight: bold;
    }
}