.modal-feedback {
  max-width: 50vw;
  .feedback-header {
    border-bottom: none;
    padding: 1.5rem 1rem;
    .modal-title {
      width: 100%;
      font-size: 1.5rem;
      color: #000;
      text-align: center;
    }
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      span {
        font-size: 1.8rem;
      }
    }
  }
  .modal-body {
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .subtitle {
      width: 100%;
      text-align: center;
      margin-bottom: 2rem;
    }
  }
  .feedback-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    p {
      margin-bottom: 0;
    }
  }
  .feedback-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2.5rem 0;
    margin: 1.5rem 0;
    border-top: solid 1px #f2f2f2;
    border-bottom: solid 1px #f2f2f2;
    :first-child {
      font-weight: bold;
    }
    p {
      :first-child {
        font-weight: normal;
        margin-right: 5px;
      }
    }
  }
  .send-feedback {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    button &:hover &:active{
      box-shadow: none;
      background-color: transparent;
      border: none;
    }
  }
}
