.modal-add-product {
    .modal-body-add-product {
        min-height: 50vh;
        padding: inherit;

        .search-bar-add-product {
            margin-bottom: 30px;
        }
    }

    .button-modal-add-product {
        display: flex;
        justify-content: flex-end;
    }
}