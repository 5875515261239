.card-order-correio{
  min-height: 35vh; 
  display: flex;
  justify-content: space-between;
 @media screen and (max-width: 768px) {
    min-height: 50vh;
 }
 @media screen and (max-width: 500px) {
  flex-direction: column;
 }
  .card-content-order-postal{
    display: flex; 
    flex-direction: column;
    .postal-column-information-box{
      margin-top: 20px;
      min-height: 12vh;
      display: flex; 
      flex-direction: column; 
      align-items: flex-start;
      justify-content: space-evenly;
    }
  }
}
.modal-track-order{
  .MuiDialog-paperFullWidth{
    max-width: 50vw;
  }
}