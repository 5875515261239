.back-arrow,
.fwd-arrow {
    position: absolute;
    top: 50%;
    left: 0;
    right: auto;
    bottom: 0;
    font-size: 10px;
    width: 30px;
    height: 30px;
    transition: all 600ms ease-in-out;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    color: #fff;
    border-radius: 50%;
    margin: 0 1.5rem;
    z-index: 1;
}

.fwd-arrow {
    right: 0;
    left: auto;
}

@media (max-width: 600px) {
  .back-arrow,
  .fwd-arrow {
    margin: 0 -0.75rem;
  }
}