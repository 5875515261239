
.order-finalized-page{
  h3{
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .order-finalized-message{
    font-style: italic;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .order-details{
    min-width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    overflow: visible;
    .checked-icon{
      font-size: 50px;
      margin-top: -1.5rem;
      margin-bottom: 40px;
    }
    p{
      margin-bottom: 5px;
      text-align: start;
      width: 100%;
      font-weight: 400;
      &.title{
        font-weight: bold;
        margin-bottom: -5px;
      }
    }
    .order-finalized-footer{
      width: 100%;
      border-top: solid 1px #ccc;
      padding: 10px 0;
      margin: 45px 0 20px 0;
      font-size: 20px;
      text-align: center;
    }
  }
  .box-status-order-finalized-page{
    display: flex;
    align-items: center; 
    justify-content: center;
    margin: 24px 0;
    padding: 4px 24px;
    background-color: rgba(255, 255, 0, 0.2);
    .title-status-order{
      margin-left: 15px;
    }
  }
 
}
