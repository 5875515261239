.order-preview {
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid #bbb;
    border-radius: 5px;
    padding: 30px;
    color: #595959;

    .order-values {
        h6 {
            margin-top: 12px;
            font-weight: 800;
            font-size: 22px;
        }

        .value {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            margin-top: 10px;
        }

        .total {
            border-top: #dddddd 1px solid;
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-family: Lato;
            font-weight: bold;
            font-size: 18px;
            margin-top: 10px;
            padding-top: 10px;
        }
    }
}

@media (max-width: 767px) {
    .order-preview {
        border: none;
        margin-top: 2rem;
    }
}