.app-notices-title {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin-bottom: 16px;
}

.app-notices-logo {
    width: 100%;
    display: flex;

    .notices-logo-img {
        max-height: 20vh;
        margin: auto;
        max-width: 100%;
        margin-bottom: 24px;
    }

}

.app-notices-description {
    text-align: center;
    font-weight: bold;
    margin-bottom: 15px;
}

.app-notices-list {
    display: flex;
    font-family: Lato;
    font-size: 16px;
    margin-top: 15px;
    margin-left: 30px;

    .notices-list-index {
        margin-right: 10px;
        border: 1px solid #D9D9D9;
        width: 32px;
        height: 32px;
        padding-top: 2px;
        display: flex;
        align-items: center;
        text-align: center;
        border-radius: 100%;
        font-weight: 900;
    }
}

.app-notices-image {
    width: 100%;
    display: flex;
    justify-content: center;

    .notices-image {
        max-width: 100%;
        max-height: 40vh;
        width: auto;
        height: auto;
        margin-bottom: 32px;
    }
}

.exitButton {
    color: #fff;
    height: 3rem;
    width: 40%;
    font-weight: bold;
    font-size: 16;
    border-radius: 5;
    border-width: 0;
}

@media screen and (max-width: 610px) {
    .modal-app-notices {
        padding: 0 30px;
    }
}