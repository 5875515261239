.items-details {
  .table-head {
    display: flex;
    border-bottom: 2px solid #F8F8F8;

    p {
      width: 25%;
      text-align: left;
      margin-bottom: 0;
      font-weight: bold;

      @media screen and (max-width: 600px) {
        display: none;

        &.description {
          display: block;
        }
      }
    }
  }

  .product-description {
    font-size: 12px;
    label {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      font-weight: normal;
      margin-bottom: 0;
    }
    .product-decription{
      display: flex;
      align-items: center;
    }
  }

  .order-items-table {
    .table-items {
      padding: 0;
      overflow: visible;
      .products{
        height: 230px;
        overflow-y: auto;
      }
    }

    .table-cell {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 25%;
      font-size: 16px;
      font-weight: 300;

      &.description {
        @media screen and (max-width: 600px) {
          width: 100% !important;
          margin-bottom: 15px;
        }
      }

      &.total-price {
        font-size: 14px;
        font-weight: bold;
        width: 10%;
      }

      &.price {
        width: 15%;
        justify-content: center;
        label{
          font-size: 12px;
          font-weight: normal;
        }

        .original-price {
          color: #ccc;
        }
      }
    }

    .table-buttons {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      border-top: solid 2px #F8F8F8;

      button {
        text-transform: capitalize;
      }
    }

  }
  
}

.edit-order-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: flex-end;
}

@media screen and (max-width: 380px){
  .items-details{
    .table-head{
      padding: 4px;
    }
  }
}