.flipbook-modal{
  .modal-body{
    height: 70vh;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .flipbook-image {
    width: 100%;
    max-height: 80vh;
    object-fit: contain;
    padding: 15px 0;
    border-bottom: solid 1px #ccc;
  }
  .flip-header{
    .modal-title{
      font-weight: bold;
      color: inherit;
    }
  }
}