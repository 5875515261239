.carousel {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scroll-snap-align: start;
    margin-bottom: 50px;
    width: 100%;
    justify-content: flex-start;
    border-radius: 10px;

    &::-webkit-scrollbar {
        display: none;
    }
}

.title {
    display: flex;
    justify-content: space-between;

    h5 {
        margin-bottom: 0;
        font-weight: bold;
    }
}

@media (max-width: 600px) {
    .products-slider-box {
        padding: 0 !important;
    }
}