.container-offers-products{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.paragraph-offers{
    width: 100%;
    font-size: 18;
    font-weight: bold;
}