.order-delivery-time {
  h5 {
    margin-bottom: 0;
    font-weight: bold;
  }
  .title-details{
    margin-bottom: 5px;
  }
  .title-time{
    margin-bottom: 5px;
    padding-top: 0;
    h5{
      font-size: 14px !important;
    }
  }

  p {
    margin-bottom: 5px;
    font-weight: normal;
    font-size: 14px;

    &.type {
      font-weight: bold;
    }

    b {
      text-transform: capitalize;
    }
  }

  .change-button {
    text-decoration: underline;
    text-transform: capitalize;
    font-size: 14px;
  }

  .box-days {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    max-width: 100%;

    .delivery-days {
      min-width: 125px;
      font-size: 12px;
      text-transform: capitalize;
      margin-right: 5px;
      border-radius: 20px;
    }
  }

  .box-times {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    max-width: 100%;

    .delivery-times {
      min-width: 120px;
      font-size: 12px;
      text-transform: capitalize;
      margin-right: 5px;
      border-radius: 20px;
    }
  }

  .order-delivery-modal {
    h5 {
      text-align: left;
      width: 100%;
    }

  }

  .buttons-icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 0px;
    padding-right: 10px;

    .icon-button {
      padding: 5px;
      color: #000;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .order-delivery-time{
    .title-details{
      margin-bottom: 5px;
    }
    .title-time{
      margin-bottom: 5px;
      padding-top: 5px;
    }
  }
  .order-delivery-time {
    .buttons-icon{
      padding-top: 0;
      padding-right: 0;
    }
  }
  
}
.radio-box-mail-methods{
  width: 100%;
  padding: 0 7% 5%;
  .box-form-control-mail-methods{
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    .form-control-mail-methods-label{
      display: flex;
      align-items: flex-start;
    }
    small{
      position: relative;
      bottom: 6px;
      left: 10%;
    }
  }
}