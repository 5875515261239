.order-preferences{
    .title-preferences{
        h5{
            font-weight: bold;
            margin: 0;
        }
    }
    .values-preferences{
        .option-title{
            margin-top: 5px;
        }
        span{
            font-size: 12px;
            font-weight: bold;
        }
        .preferences-radio{
            padding: 2px 9px;
        }
        .checked{
            .MuiFormControlLabel-label{
                color: #595959;
            }
        }
        .MuiFormControlLabel-label{
            color: #a5a5a5;
        }
    }
}