/*!

=========================================================
* Argon Design System React - v1.0.0 based on Argon Design System - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import "bootstrap/functions";
@import "custom/functions";

@import "custom/variables";
@import "bootstrap/variables";

@import "bootstrap/mixins";
@import "custom/mixins";

// Bootstrap components

@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/print";

// Custom components

@import "custom/reboot.scss";
@import "custom/global.scss";
@import "custom/utilities.scss";

@import "custom/accordion.scss";
@import "custom/alerts.scss";
@import "custom/avatars.scss";
@import "custom/badge.scss";
@import "custom/buttons.scss";
@import "custom/card.scss";
@import "custom/carousel.scss";
@import "custom/close.scss";
@import "custom/custom-forms.scss";
@import "custom/dropdown.scss";
@import "custom/footer.scss";
@import "custom/forms.scss";
@import "custom/grid.scss";
@import "custom/icons.scss";
@import "custom/input-group.scss";
@import "custom/list-group.scss";
@import "custom/modal.scss";
@import "custom/nav.scss";
@import "custom/navbar.scss";
@import "custom/pagination.scss";
@import "custom/popover.scss";
@import "custom/progress.scss";
@import "custom/section.scss";
@import "custom/separator.scss";
@import "custom/type.scss";

// Vendor

@import "custom/vendor/bootstrap-datepicker.scss";
@import "custom/vendor/headroom.scss";
@import "custom/vendor/nouislider.scss";

// React differences

@import "react/react-differences.scss";

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #b8b8b8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a5a5a5;
}

// ::-webkit-scrollbar {
//   display: none;
// }

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
  background: #fff;
  overflow: auto !important;
  -wekit-font-smoothing: antialiased !important;
}

.business-button {
  min-width: 130px;
}

.business-image {
  max-height: 60px;
}

.market-image {
  max-height: 150px;
  max-width: 100%;
}

.market-next-delivery-hour {
  display: block;
  max-width: 100%;
}

.spinner {
  text-align: center;
}
.alert {
  z-index: 9999 !important;
}
.carousel-inner {
  height: 100%;
}
.spinner > div {
  width: 18px;
  height: 18px;
  background-color: $orange;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.full-width-navitem {
  width: 100%;
  text-align: right;
}

@media screen and (max-width: 1199px) {
  .market-sidebar {
    margin-top: 53px;
  }
}

@media screen and (min-width: 768px) {
  .market-sidebar {
    margin-top: 0.4rem;
  }

  #navbar-main {
    height: 41px;
  }

  #navbar-main span {
    height: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 90vw;
    white-space: nowrap;
    display: inline-block;
  }
}

// top: 107px;
.nav-pills > li > a.text-warning.active {
  color: white !important;
}

.shelf {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -7.2rem;
  z-index: 4;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.chart-product-image {
  width: 100%;
  display: block;
}

.price-card {
  margin-top: -1.1rem;
  z-index: 6;
}

.product-card-body {
  min-height: 290px;
}

.bottom-shadow {
  box-shadow: 0 4px 3px -1px #ccb8b894;
}

.shadow-none {
  box-shadow: none !important;
}

.circle-corners {
  border-radius: 5px !important;
}

.floating-button-right {
  position: fixed !important;
  bottom: 45px;
  right: 10px;
  width: 60px;
  height: 60px;
  margin: auto;
}

.floating-button-left {
  position: fixed !important;
  bottom: 40px;
  left: 25px;
  width: 60px;
  height: 60px;
  margin: auto;
}

@media screen and (min-width: 577px) {
  .cart-menu {
    padding-top: 0 !important;
    width: 450px;
    max-height: 65vh;
    overflow: scroll;
  }

  .text-sm {
    font-size: 12px !important;
  }

  .sad-class {
    font-size: 12rem;
  }

  .sad-div {
    margin-top: 1rem;
  }
  .sad-div > h3 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 576px) {
  .cart-menu {
    padding-top: 0 !important;
    width: 90vw;
    max-height: 65vh;
    overflow: scroll;
  }

  .product-card-body {
    min-height: 310px;
  }

  .text-sm {
    font-size: 0.7rem !important;
  }

  h6 {
    font-size: 0.8rem;
  }

  h4 {
    font-size: 1rem;
  }

  .business-button {
    min-width: 90px;
  }

  .business-image {
    max-height: 40px;
  }

  .header-icon {
    font-size: 3rem !important;
  }

  .card-text {
    font-size: 0.65rem !important;
  }

  .sad-class {
    font-size: 10rem;
  }

  .sad-div {
    margin-top: 1rem;
  }
  .sad-div > h3 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .mobile-home-padding {
    padding: 0.75rem
  }
}

@media screen and (max-width: 767px) {
  .main-content {
    margin-left: 0px !important;
    padding-top: 200px !important;
    &.with-banner{
      padding-top: 150px !important;
    }
  }
}

@media screen and (max-width: 991px) {

  .grams-label {
    width: 60px;
    height: 30px;
    right: -5px;
    bottom: 0;
    border-radius: 10px;
    position: absolute;
    white-space: nowrap;
  }
  .grams-label > span {
    font-size: 11px;
  }

  .floating-button-left {
    position: fixed !important;
    bottom: 40px;
    left: 0px;
    width: 60px;
    height: 60px;
    margin: auto;
  }
}

.blink-me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.4;
  }
}

.bg-disabled {
  background: #e9ecef !important;
}

ol.progtrckr {
  margin: 0;
  text-align: center;
  padding-bottom: 2.2rem;
  list-style-type: none;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  padding: 0 0.7rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media screen and (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}

.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media screen and (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid $orange;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid $orange;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #0fa0ce;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #0fa0ce;
}
ol.progtrckr li.progtrckr-done:hover:before {
  color: #0fa0ce;
}

.card-image {
  max-width: 15vw;
  margin-right: 10px;
}

.header-icon {
  font-size: 4rem;
}

.bounce {
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-30px);
  }

  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.description-font {
  font-size: 0.8rem;
}

.break-me {
  white-space: pre-wrap;
}

.main-content {
  padding-top: 260px;
  &.with-banner{
    padding-top: 205px;
  }
}

@media screen and (min-width: 992px) {
  .mt-36 {
    margin-top: 3.6rem !important;
  }

  .market-sidebar {
    margin-top: 53px;
  }

  .mt-md-8 {
    margin-top: 5rem !important;
  }

  .main-content {
    padding: 0 1.5rem;
    margin-left: 215px;
    padding-top: 220px;
    &.with-banner{
      padding-top: 210px;
    }
  }

  .grams-label {
    width: 80px;
    height: 40px;
    right: -10px;
    bottom: 0;
    border-radius: 10px;
    position: absolute;
    white-space: nowrap;
  }
}

.br-10 {
  border-radius: 10px;
}

.cep-image {
  height: 200px;
  max-width: 100%;
  object-fit: contain;
}

.order-image {
  height: 50px;
  max-width: 100%;
  object-fit: contain;
}

.custom-control-label::before {
  content: none;
}

.custom-label-checkbox {
  position: absolute;
  top: 0.125rem;
  left: -1.75rem;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  border: 1px solid #cad1d7;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.custom-radio .custom-label-checkbox {
  border-radius: 10px;
}

.free-delivery-badge {
  width: 40px;
  height: 40px;
  margin-top: -40px;
}

.detail {
  display: none;
}

.show-detail-on-hover:hover + .detail {
  display: block;
}

.bg-black {
  background: black;
}

@media screen and (min-width: 1900px) {
  .promotion-label {
    margin-left: 2px;
    margin-right: 2px;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1899px) {
  .promotion-label {
    margin-left: 2px;
    margin-right: 2px;
    font-size: 0.6rem;
  }
}

.normal-whitespace {
  white-space: normal;
}

.mt-8,
.my-8 {
  margin-top: 4.8rem !important;
}
.reset-text-transform {
  text-transform: none;
}

@media screen and (min-width: 1280px) {
  .w-lg-20 {
    flex-grow: 0 !important;
    max-width: 20% !important;
    flex-basis: 20% !important;
  }
}

.cep-button {
  margin: 10px 0px;
  color: rgb(255, 255, 255) !important;
  flex: 1;
}

.nav-categories {
  border-radius: 0;
}

.category-tab {
  color: white !important;
  background: transparent !important;
  box-shadow: none !important;
}

.category-tab.active {
  border-bottom-color: white;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-radius: 0;
}

.filter-tab {
  color: inherit !important;
}

.filter-tab.active {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-radius: 0;
  background: transparent !important;
}

.nav-categories .nav-link.filter-tab.active {
  color: inherit !important;
}

.amount-in-kilo-label {
  padding: 3px 10px;
  border-radius: 10px;
  color: white;
  font-size: 14px !important;
}
