.modalTakeMore {
  max-width: 550px;
  .modal-top {
    height: 12px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  .container {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    .title {
      text-align: center;
      margin-bottom: 30px;
      font-style: italic;
      flex-direction: row;
      font-size: 22px;
      font-weight: 900;
      font-family: lato;
      color: #1b1b1b;
    }
    .container-products {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      text-align: left;
      align-items: center;
      border-width: 1px;
      border-color: #bbbbbb;
      border-style: solid;
      border-radius: 5px;
      justify-content: center;
      margin: 30px 0px 45px;
      color: #686868;
      justify-content: space-around;
      .product {
        display: flex;
        align-items: center;
        margin: 16px 0px;
        img {
          height: 60px;
          width: 60px;
          margin-right: 15px;
        }
        .product-description {
          color: #686868;
          font-weight: 700;
          font-style: normal;
          font-size: 14px;
          max-height: 42px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
      }
      h2 {
        margin: auto 0px;
        text-align: center;
      }
    }
    button {
      border-radius: 5px;
      margin: 0 auto;
      border-style: none;
      padding: 10px 15px;
      font-size: 14px;
      font-family: lato;
      font-weight: 900;
      color: #fff;
      cursor: pointer;
    }
  }
}
