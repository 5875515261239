.order-card {
  border: solid 1px #ccc;
  // min-width: 48%;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px 15px;
  .order-button-dropdown {
    background-color: transparent;
    box-shadow: none;
    border: 0;
    &:hover {
      transform: translateY(-1px);
      background-color: #eeeeee;
    }
  }
  .order-button {
    width: 100%;
    text-transform: none;
    text-align: start;
    padding: 0;
    border-color: transparent;
    background: transparent;
    box-shadow: none;
    overflow: hidden;
    .order-date {
      font-size: 16px;
      background-color: #f2f2f2;
      padding: 10px 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
      margin-bottom: 15px;
      border-radius: 5px;
      //height: 100%;
    }
    &:active {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
  .send-feedback {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    p {
      margin-bottom: 0;
    }
    &:hover {
      transform: translateY(-1px);
    }
  }
  .open-chat {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    // padding: 5px 0;
    p {
      margin-bottom: 0;
      margin-left: 5px;
      font-weight: bold;
    }
    &:hover {
      transform: translateY(-1px);
    }
  }
  .text-details-address {
    font-size: 0.75rem;
    margin-bottom: 5px;
    word-wrap: break-word;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;
    max-height: 32px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .text-details {
    font-size: 0.75rem;
    margin-bottom: 5px;
  }

  .order-number {
    font-size: 1rem;
    font-weight: bold;
  }
}

.btn:not(:last-child) {
  margin-right: 0 !important;
}
.question-response-modal{
  top: 30%;
 .title-response-modal-header{
  border-bottom: none;
 }
 .response-modal-footer-button{
  border-top: none;
  justify-content: space-between;
 }
}