.modal-user-login {
  .MuiDialog-paperWidthMd {
    max-width: 570px;
    min-height: 350px;
    border-radius: 2%;

    .div-modal-default {
      height: 0 !important;
      background-color: #fff !important;
    }
  }

  .button-logout-modal-user {
    text-transform: none;
    font-weight: 800;
    float: right;
  }

  .div-title-img-user-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 3%;
      max-width: 270px;
    }

    p {
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
    }

    span {
      font-size: 18px;
      font-weight: 300;
      line-height: 2px;
      margin-bottom: 5%;
    }

  }

  .method-new-auth-buttons {
    display: flex;
    flex-direction: column;

    button {
      margin-top: 5px;
      text-transform: none;

      img {
        position: relative;
        top: 1px;
        right: 5%;
      }
    }
  }
}

.custom-firebase-auth {
  width: 100%;

  .firebaseui-idp-button {
    display: flex;
    justify-content: center;
    max-width: none;
  }
}