.input-wrapper {
    display: flex;
    text-align: center;

    input {
        height: auto;
        padding: 0;
        text-align: center;
        border: 0;
        font-weight: bold;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}



.weight-input {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    input {
        width: 60%;
        display: flex;
        text-align: right;
    }

    span {
        width: 30%;
        margin-left: 1rem;
    }
}