.order-details{
    .title-details{
        h5{
            font-weight: bold;
            margin: 0;
        }
    }
    .values-details{
        span{
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 2px;
            display: flex;
            justify-content: space-between;
            &.discount{
                color: red;
            }
        }
        p{
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            border-top: solid 2px #F8F8F8;
            padding-top: 15px;
        }
    }
}