.sections-menu {
    display: flex;
    gap: 0.5rem;
    font-weight: 600;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-bottom: 1.5rem;
}

.sections-menu::-webkit-scrollbar {
    display: none;
}