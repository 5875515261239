.crm-modal {
  .close{
    .span-crm-modal-button-close{
      width: 98%;
      display: flex;
      justify-content: flex-end;
      margin-top: 3%;
      font-size: 30px;
    }
  }
  .modal-body{
    width: 100%;
    height: 100vh;
  }
  .MuiDialog-paperWidthMd {
    max-width: 460px
  }
}

@media screen and (max-width: 600px) {
  .crm-modal {
    .modal-body {
      height: 120vh;
      .switch{
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}