.box-phone-login-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
  }

  span {
    font-weight: 300;
  }
}

.title-alert-auth-phone {
  margin: 1em 3% 0;
  font-size: 14px;
  font-weight: 400;
}