.addition-approval-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.addition-approval-popup-card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    width: 500px;
    height: 250px;
}

.addition-approval-popup-content {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.addition-approval-popup-info {
    p {
        font-weight: normal;
    }
}

.addition-approval-popup-message {
    background: #d9d9d9;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
}

.addition-approval-popup-actions {
    padding-top: 10px;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #d9d9d9;

    button {
        text-transform: capitalize;
        box-shadow: none;

        &:focus {
                outline: none;
                box-shadow: none;
            }
    }
}