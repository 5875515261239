$show-all-font-weight: bold;

@keyframes button-pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.075);
    }

    100% {
        transform: scale(1);
    }
}

.show-all,
.show-all-carousel {
    border: none;
    font-weight: $show-all-font-weight;
}

.show-all {
    text-transform: capitalize !important;
}

.show-all-carousel {
    margin-left: 1rem;
    animation: button-pulse 1s infinite;
    background: transparent !important;
}

.show-all:hover,
.show-all-carousel:hover {
    cursor: pointer;
}

.show-all:focus,
.show-all-carousel:focus {
    outline: none;
    border: none;
}