.addition-approval-box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    width: 100%;
    height: 200px;
    margin-top: 20px;
}

.addition-approval-box-content {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    h4 {
        color: #292929;
    }

    p {
        font-weight: normal;
    }
}

.addition-approval-box-message {
    background: #d9d9d9;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
}

.addition-approval-box-actions {
    padding-top: 10px;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #d9d9d9;

    button {
        text-transform: capitalize;
        box-shadow: none;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

.addition-approval-box-approved {
    display: flex;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    align-items: center;
    justify-content: space-around;
    width: 350px;
    margin-top: 20px;
    padding: 1rem;

    div {
        display: flex;
        flex-direction: column;
        font-size: smaller;
        font-weight: normal;
    }

    svg {
        font-size: 50px;
        margin-right: 20px;
    }
}

.addition-approval-box-disapproved {
    background: #d9d9d9;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        font-size: 16px;
        margin-right: 5px;
    }
}