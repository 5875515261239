.redirect-menu {
    display: flex;
    margin: -2% 0 2rem;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 1rem 2rem;

    button {
        text-transform: capitalize;
        margin-right: 2rem;
        border-radius: 20px;
        min-width: 135px;
        height: 40px;


        :focus {
            outline: none;
        }

    }

    @media screen and (max-width: 599px) {
        height: 45px;
        padding: 0;

        button {
            margin-right: 1rem;
        }
    }
}

.redirect-menu::-webkit-scrollbar {
    display: none;
}