.link-header {
  display: flex;
  flex-direction: row;
  margin-left: 0;
  padding-left: 5px;
  margin-right: 0px;
  padding-right: 0;
  justify-content: flex-start;
  align-self: flex-start;
  // align-self: center;
  align-items: center;
  font-size: .9rem;

  .text {
    font-size: .9rem;
  }

  &:hover {
    cursor: pointer;
    box-shadow: none;
    transform: none;
  }

  &__mobile {
    padding: 0;
    margin-left: 0;
  }
}

.address-new-header {
  width: 100%;
  display: flex;
  align-items: center;

  // justify-content: center;
  .text {
    font-size: .8rem;
    padding-left: 5px;
    font-weight: normal;
    max-width: 65%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 1124px) {
      max-width: 55%;
    }
  }

  &__mobile {
    display: none;
    background-color: #ccc;
    padding: 5px 10px;

    .textTitle,
    .text {
      color: #fff;
    }

    @media screen and (max-width: 992px) {
      display: flex;

      .textTitle {
        font-size: .8rem;
        display: flex;
        align-items: center;
      }

      .text {
        max-width: 50%;
      }
    }

    @media screen and (max-width: 360px) {
      .textTitle {
        font-size: 12px;
      }

      .text {
        max-width: 35%;
        font-size: 10px;
      }
    }
  }
}


.toolbar-content {
  width: 100%;
  padding: 0 !important;
  display: flex;
  flex-direction: column;

  .hide-in-mobile {
    justify-content: space-between;
    height: 45px;

    @media screen and (max-width: 992px) {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }
  .hide-in-mobile-box{
    @media screen and (max-width: 599px) {
      display: none;
    }
  }

  .hide-in-mobile-search {
    @media screen and (min-width: 1124px) {
      width: 50% !important;
    }

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .automatic-coupon {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    padding: 5px 0;
    font-weight: bold;
    @media screen and (max-width: 599px) {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 10px;
      font-size: 12px;
    }

    @media screen and (max-width: 360px) {
      font-size: 10px;
    }
  }

  .menu-mobile {
    display: flex;
    justify-content: space-between;
  }

  .menu-mobile-button {
    display: none;

    &.search-bar-mobile {
      width: 100%;
      margin-bottom: 10px;

      @media screen and (max-width: 992px) {
        display: block;
      }
    }
    @media screen and (max-width: 599px) {
      display:list-item;
    }
  }

  .header-logo-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    max-width: 15%;

    img {
      padding-right: 20px;
      max-width: 100%;
      height: 100px;
      object-fit: contain;

      @media screen and (max-width: 992px) {
        height: 50px;
        max-width: 150%;
      }

      @media screen and (max-width: 600px) {
        max-width: 270%;
        padding: 0;
      }
    }
  }

  .header-button {
    margin: 0 10px;
    font-size: 14px;
    text-transform: capitalize;
    height: 100%;
  }

  input,
  .input-group-text {
    background-color: #F5F5F5 !important;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .text {
    // margin-left: 10px;
    margin-right: 0px;
    font-size: 12px;
  }

  .textTitle {
    font-size: 14px;
  }
}