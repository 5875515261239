.header-card-listing{
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  width: 100%;
  margin: 10px auto 5px;
 
  .button-new-card-menu{
    text-transform: capitalize;
    outline: none;
    font-size: 15px;
    padding-left: 0;
  }
  .title-card-listing-menu{
    line-height: 45px;
  }
}