.promotion-text {
  font-weight: 700 !important;
  width: 100%;
  text-align: end !important;
  font-style: italic;
}

.store-page-footer {
  position: fixed;
  bottom: 0;
  background-color: #F8F8F8;
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;

  @media screen and (max-width: 576px) {
    font-size: 8px;
  }
}

.categories-view {
  .icon-button {
    color: #fff;
    width: 15px;
    margin: 0 15px;
    border-radius: 5px;
  }
  .menu-scroller {
    display: flex;
    flex-wrap: inherit;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;

    .category-tab {
      width: max-content;
      font-weight: normal;
    }
  }

  .menu-scroller::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
  }
}