.order-payment-infos {
  h5 {
    margin-bottom: 0;
    font-weight: bold;
  }

  .change-button {
    text-decoration: underline;
    text-transform: capitalize;
    font-size: 14px;
  }
  .title-details{
    width: 100%;
  }
  .input-group-alternative{
    box-shadow:none;
    margin-top: 0 !important;
    input{
      border: solid 1px #ccc;
      color: #000;
    }
  }
}
.payment-card-infos{
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 15px;
  border: solid 1px #000;
  border-radius: 5px;
  .title{
    font-weight: bold;
  }
  p{
    font-size: 14px;
    margin: 0;
  }
  .button-change-card{
    color: white;
    text-transform: capitalize;
    text-decoration: underline;
    float: right;
    bottom: 10%;
    &:focus{
      outline: none
    }
  }
  .payment-card-image{
    display: flex;
    align-items: center;
    p{
      margin-right: 20px;
    }
    img{
      width: 25%;
    }
  }
}
.order-payment-inputs-form {
  display: flex;
  justify-content: space-between;

  .switch-label {
    margin: 15px 0;

    span {
      font-size: 14px;
    }
  }
  h5{
    font-size: 16px;
    font-weight: normal;
    color: #808080;
  }
  .input-item {
    margin-top: 10px;
  }

  .modal-client-footer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
  }
  .card-first-infos{
    padding-left: 10px;
    @media screen and (max-width: 992px) {
      margin-top: 10px;
      padding-left: 0;
    }
  }
}

.modal-card-listing{
  .MuiDialog-paperWidthMd{
    max-width: 510px;
  }
}