.header-card-listing-check {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  h4 {
    width: 100%;
    font-weight: bold;
  }

  p {
    color:#808080;
    font-weight: 400;
  }

  .title-card-listing-check {
    line-height: 45px;
  }

  .card-listing-btn {
    position: relative;
    left: 110px;
    float: right;
    line-height: 10px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
    @media screen and (max-width: 500px){
      position: inherit;
      line-height: 0;
    }
  }

  .card-listing-btn:focus {
    outline: none;
  }
}

.body-card-listing-check {
  .button-add-cvv {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 570px){
      flex-direction: column;
    }
    .button-new-card-checkout{
      border-radius: 10px;
      @media screen and (max-width: 570px){
        font-size: 18px;
      }
    }
    
    .button-confirm-cvv {
      margin: 20px;
    }

    .button-new-card-checkout:focus,
    .button-confirm-cvv:focus {
      outline: none;
    }
  }

}

.title-confirm-cvv{
  h4 {
    width: 100%;
    font-weight: bold;
  }

  p {
    color:#808080;
    font-weight: 400;
    text-align: initial;
  }
  .btn-close-cvv{
    line-height: 25px;
    float: right;
  }
  
}

.input-cvv{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 10px;
}

.confirm-cvv-modal{
  .modal-body{
    display: block;
    
  }
  .input-text-field{
    @media screen and (max-width: 400px) {
      width: 130px;
    }
  }
  .MuiDialog-paperWidthMd {
    max-width: 500px;
  }
}

