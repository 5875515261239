.modal-terms-policy {
  .modal-content {
    height: 75vh;
    // overflow: scroll;
  }
  .modal-terms-body {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    // height: 70%;
    // overflow: scroll;
  }
  .modal-footer {
    justify-content: space-between;
    padding: 0 1.5rem;
  }
}
