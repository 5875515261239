.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.dialog-card,
.dialog-card-variant {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 15px 25px;
    border-radius: 5px;
    width: 320px;
    height: 320px;
}

.dialog-card {
    justify-content: space-around;
}

.dialog-card-variant {
    text-align: center;
    justify-content: space-around;

    h5 {
        font-weight: bold;
    }

    svg {
        font-size: 45px;
    }
}

.dialog-card img {
    width: 100px;
    height: auto;
}

.dialog-card small {
    text-align: center;
}

.dialog-card button {
    color: #fff;
}

.dialog-card-variant button {
    width: 150px;
    color: #fff;
    text-transform: capitalize;
}

.dialog-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85px;
}