.box-email-login-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
  }

  span {
    font-weight: 300;
  }
}


.button-next-email-login {
  width: 97%;
  margin: auto !important;
}

.email-authe-password {
  .icon-visibility-password {
    position: relative;
    bottom: 1.5em;
    float: right;
    z-index: 10;
  }
  .button-problems-email-login{
    text-transform: none;
    color: red;
    margin: 15px 0 5px;
    border-bottom: none;
    transition: border-bottom 0.3s ease;
    cursor: pointer;
    &:focus {
      outline: none;
      border-bottom: 2px solid red;
    }
  }
}

.email-password-recovery-buttons{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 15%;
}

.auth-email-google-button{
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 35px;
  @media screen and (max-width: 600px) {
      top: 0;
    }
}

.help-buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
  button {
    width: auto;
  }
}