.modal-home {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/img/back-home.png");
  background-size: cover;
  .background {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    // opacity: 80%;
  }
  .modal-body::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  .modal-body {
    max-width: 100%;
    min-height: 80vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 2.5rem 0 !important;
    &.register {
      justify-content: flex-start;
      height: 80vh;
      .logo {
        margin-bottom: 20px;
      }
      .loading {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    @media (max-width: 600px) {
      padding: 1rem 0 !important;
      .logo {
      }
    }
    .logo {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      h3 {
        font-weight: bold;
      }
      img {
        width: 100%;
        margin: 2.5rem 0;
      }
      p {
        position: absolute;
        bottom: 10px;
        color: #000;
        a {
          color: #fff;
        }
      }
    }
    .login {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      background-color: #fff;

      .form {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .MuiGrid-root {
          padding-right: 10px;
        }
        .input-group-alternative {
          box-shadow: none;
          margin-top: 0 !important;
          input {
            border: 1px solid #cad1d7;
          }
        }
      }
      .select {
        width: 100%;
        margin-bottom: 10px;
      }
      input {
        width: 100%;
        margin-bottom: 10px;
      }
      .label-password {
        margin-top: -8px;
        margin-bottom: 10px;
      }
      .buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 20px;
        .or {
          width: 40%;
          height: 1px;
          margin: 20px 0;
          display: block;
          border-top: 1px solid #ccc;
          border-bottom: none;
          border-left: none;
          border-right: none;
          text-align: center;
          span {
            background-color: white;
            position: relative;
            top: -0.8em;
            padding: 0 10px;
          }
        }
        button {
          width: 50%;
          // margin-top: 15px;
        }
      }
    }
    .terms {
      text-align: center;
      margin-top: 20px;
      flex-basis: 0;
      p {
        margin: 0px;
        font-size: 12px;
      }
    }
  }
}
