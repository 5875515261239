.cardTakeMore {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  border-width: 1px;
  border-color: #bbbbbb;
  border-style: solid;
  border-radius: 5px;
  justify-content: center;
  margin: 30px 0px 45px;
  color: #686868;
  min-width: 400px;
  .container-products {
    display: flex;
    justify-content: space-around;
    width: 100%;
    .product {
      display: flex;
      align-items: center;
      margin: 16px 0px;
      img {
        height: 60px;
        width: 60px;
        margin-right: 15px;
      }
      .product-description {
        color: #686868;
        font-weight: 700;
        font-style: normal;
        font-size: 14px;
        max-height: 42px;
        word-wrap: break-word;
        white-space: pre-line;
        overflow: hidden;
      }
    }
    h2 {
      margin: auto 0px;
      text-align: center;
    }
  }
  button {
    border-radius: 5px;
    margin: 0 auto 15px;
    border-style: none;
    padding: 10px 15px;
    font-size: 14px;
    font-family: lato;
    font-weight: 900;
    color: #fff;
    cursor: pointer;
  }
}
