.user-page{
  .nav-link{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    &:hover{
      border: none;
      font-weight: 600;
    }
  }
  .active {
    font-weight: bold;
  }
}